import request from '@/plugins/axios'

// 域名列表
export const apiDomainLists = (params: any) => request.get('/domain/lists', { params })

// 添加域名
export const apiDomainAdd = (params: any) => request.post('/domain/add', params)

// 域名详情
export const apiDomainDetail = (params: any) => request.get('/domain/detail', { params })

// 编辑域名
export const apiDomainEdit = (params: any) => request.post('/domain/edit', params)

// 删除域名
export const apiDomainDelete = (params: any) => request.post('/domain/delete', params)

// 选择域名
export const apiDomainSelect = () => request.get('/domain/select')
