
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { apiChangeWallet } from '@/api/shop'
@Component({
  components: {}
})
export default class LsWalletChange extends Vue {
    @Prop() value?: number
    @Prop() type?: number // 变动类型：1-用户金额；2-可提现金额；3-积分
    @Prop() sid?: number // 商户id
    @Prop({
      default: ''
    })
    title!: string // 弹窗标题

    @Prop({
      default: '660px'
    })
    width!: string | number // 弹窗的宽度

    @Prop({
      default: '20vh'
    })
    top!: string | number // 弹窗的距离顶部位置

    /** S Data **/
    visible = false
    $refs!: {
        valueRef: any
    }

    form = {
      sid: 0, // 用户id
      type: 6, // 变动类型：1-用户金额；2-可提现金额；3-积分
      action: 1, // 调整类型：0-减少；1-增加
      num: 0, // 调整数量
      remark: '', // 备注
      value: 0 // 初始值
    }

    typeName = '' // 变动类型名称

    hasDay = true // 变动类型名称

    // 修改后的值
    get lastValue (): number {
      let total = this.value
      if (this.form.action === 1) {
        total = Number(this.form.value) + this.form.num * 1
      } else {
        total = Number(this.form.value) - this.form.num * 1
      }
      return total
    }
    /** E Data **/

    @Watch('sid', {
      immediate: true
    })
    getSid (val: number): void {
      // 初始值
      // console.log('userId', val)
      // this.form.value = val
      this.$set(this.form, 'sid', val)
    }

    @Watch('value', {
      immediate: true
    })
    getValue (val: number): void {
      // 初始值
      // console.log('value', val)
      // this.form.value = val
      this.$set(this.form, 'value', val)
    }

    @Watch('type', {
      immediate: true
    })
    getType (val: number): void {
      if (val === 1) {
        this.typeName = '余额'
        this.$set(this.form, 'type', val)
      } else {
        this.typeName = '可以提现余额'
        this.$set(this.form, 'type', val)
      }
    }

    /** S Methods **/
    // 调整用户钱包
    updateWallet (): void|unknown {
      const num = this.form.num * 1
      if (num <= 0) {
        return this.$message.error('请输入大于0的数字')
      }
      apiChangeWallet(this.form)
        .then(() => {
          // 重新获取用户详情
          this.$emit('refresh')
          this.visible = false
        })
        .catch(() => {
          this.visible = false
        })
    }

    onTrigger (): void {
      this.visible = true
    }

    // 关闭弹窗
    close (): void {
      this.visible = false

      // 重制表单内容
      this.$set(this.form, 'num', 0)
      this.$set(this.form, 'remark', '')
    }
    /** E Methods **/

  /** S Life Cycle **/
  /** E Life Cycle **/
}
